import React, { useRef } from 'react';
import Button from '../../../components/atoms/Button';
import { withNamespaces } from 'react-i18next';
import { withSitecoreContext, Text } from '@sitecore-jss/sitecore-jss-react';
import Grid from '../../../components/molecules/Grid';
import Box from '../../../components/molecules/Box';
import Typography from '../../../components/atoms/Typography';
import Title from '../../../components/atoms/Title';
import Picture from '../../../components/atoms/Picture';
import { get } from 'lodash';
import useStyles from './style';
import { COMPONENT_NAME } from './locators';
import {
  pushAnaylyticsData,
  createDynamicLabels,
} from '../../../../utils/analytics';
import { analyticsData } from '../../../../constants/Analytics/analytics-data';
import withErrorBoundary from '../../../utils/HOCs/withErrorBoundary';
import {
  getComponentName,
  getPageName,
} from '../../../utils/getPageComponentInfo';
import TextRotateAnimation from '../../../components/atoms/TextRotateAnimation';
import aspectRatios from '../../../utils/AspectRatios';
import checkEmptyObject from '../../../utils/checkEmptyObject';
import { getUId } from '../../../../utils/utility';

const BoxOverlayImage = props => {
  const fields = props.fields;
  const params = props.params;
  const uid = getUId(props)
  const isFieldsEmpty = checkEmptyObject(fields);
  const { segmentText, title, image, imageMobile } = fields;
  const description = fields.description;

  const componentList = get(
    props,
    'sitecoreContext.route.placeholders["vad-main"][0].placeholders[no-search-result]',
    '[]'
  );

  const componentLength = componentList && componentList.length;

  const lastComponentObject =
    componentList &&
    componentList.length > 0 &&
    componentList[componentLength - 1];

  let lastComponent = get(props, 'appConfig.lastComponent', '');
  const componentName = getComponentName(props);

  if (
    lastComponent &&
    lastComponent.toLowerCase() === 'globalsearchsublayout'
  ) {
    lastComponent = lastComponentObject && lastComponentObject.componentName;
  }

  const classes = useStyles(props);

  const addAnalyticsInfo = index => {
    const seoText = get(fields, `seoEnglishCta${index + 1}.value`, '');
    const obj = {
      ...analyticsData.pageBodyInteraction.arrowClick,
      ...{
        label: seoText,
      },
    };
    pushAnaylyticsData(obj);
  };

  const totalLinks = Object.keys(props && props.fields).filter(
    propertyName => propertyName.indexOf('link') === 0
  );

  const links = () => {
    let arr = [];
    for (let i = 1; i <= totalLinks.length; i++) {
      arr.push(get(props, `fields[link${i}]`));
    }
    return arr;
  };

  const ctaElements = links();

  const infoBox = useRef(null),
    pictureElem = useRef(null);

  const isImageRightPosition = params && params["Component Type"].toLowerCase() === "right";
  const hasDescription = description && description.value;

  return (
    isFieldsEmpty ?
      <></> :
      <div
        className={`${classes.root} ${lastComponent === componentName ? 'marginBottom-0' : ''}`}
        data-locator={COMPONENT_NAME}
        ref={infoBox}
        uid={uid}
      >
        <Grid className={classes.mainContainer}>
          <Grid container className={`rowWrapper ${isImageRightPosition ? classes.rowReverse : ''}`} alignItems="center">
            <div
              className="imageWrapper"
            >
              <div className={classes.pictureWrap} ref={pictureElem}>
                <Picture
                  media={{
                    image: image,
                    mobileImage: imageMobile,
                    aspectRatio: aspectRatios['2x1-m'],
                    preferredSize: '2x1-m',
                  }}
                  disableLazyLoad
                  className={'display-picture'}
                  isParalax={true}
                />
                {segmentText && (
                  <div className="segment-title">
                    <TextRotateAnimation
                      condition={true}
                      animateSettings={{
                        willAnimate: true,
                        selfTrigger: true,
                        duration: '250ms',
                      }}
                    >
                      <Title
                        text={segmentText}

                      />
                    </TextRotateAnimation>
                  </div>
                )}
              </div>
            </div>

            <div className={`overlap-txt-wrap ${!hasDescription ? "overlay-flex" : ""}`}>
              {title && title.value && (
                <Typography
                  variant={hasDescription ? "h5Bold" : "h4"}
                  component="h5"
                  gutterBottom={false}
                  className={'sec-title no-css-border'}
                  border={false}
                >
                  <Text
                    field={title}
                  />
                </Typography>
              )}
              {hasDescription && (
                <Typography
                  variant="body1"
                  component="div"
                  gutterBottom={false}
                  className={'sec-description no-css-border'}
                  border={false}
                >
                  {description.value}
                </Typography>
              )}
              {ctaElements && (
                <Box className={classes.actionBlock}>
                  {ctaElements.map(
                    (item, i) =>
                      item.value &&
                      item.value.text && (
                        <div className={'btn-link'} key={i}>
                          <Button
                            onClick={() =>
                              addAnalyticsInfo(i)
                            }
                            field={item}
                            component="span"
                            className={classes.buttonInfoBox}
                            buttonType="whitePillContrast"
                            size={'small'}
                            hasBorder={false}>
                          </Button>
                        </div>
                      )
                  )}
                </Box>
              )}
            </div>
          </Grid>
        </Grid>
      </div>
  );
};

export default withErrorBoundary(
  withSitecoreContext()(withNamespaces()(BoxOverlayImage))
);
